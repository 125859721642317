﻿@import "fontawesome/font-awesome";

$tertiary-fontcolor: #10559A !important;

.ccl-button-small,
.ccl-button-xsmall,
.ccl-button-primary-small,
.ccl-button-secondary-small,
.ccl-button-tertiary-small,
.ccl-button-disabled-small{
    &.ccl-button-link{
        padding-right: 32px;
        &:after{
            margin: 0;
        }
    }    
}

.ccl-button-primary-small{
    &.ccl-button-link{
		&.view-more{
			&:after{
				margin: 0;
			}
		}
    }    
}

.ccl-button-secondary-small {
    &.ccl-button-link {
        &.ch-cta-2016 {
            &.cta {
                &:after {
                    margin: -6px 0 0 -25px;
                }
            }
        }
        &.explore-center, &.view-more {
            &:after{
                margin: 0;
            }
        }
    }
}

#ccl-refresh-homepage {
    .max-width {
        .ccl-button-small,
        .ccl-button-xsmall,
        .ccl-button-primary-small,
        .ccl-button-secondary-small,
        .ccl-button-tertiary-small,
        .ccl-button-disabled-small{
            &.ccl-button-link {
                &:after {
                    margin: 0;
                    display:none;
                }
            }
        }
    }

    .ccl-tout-back {
        .ccl-button-small,
        .ccl-button-xsmall,
        .ccl-button-primary-small,
        .ccl-button-secondary-small,
        .ccl-button-tertiary-small,
        .ccl-button-disabled-small{
            &.ccl-button-link {
                &:after {
                    margin-top: -6px;
                    margin-left: -29px;
                }
                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    &:after {
                        margin-left: -25px;
                    }
                }
            }
        }
    }
}

.ccl-button-link i{
    @extend .fa;
    @extend .fa-caret-right;
    font-size: 18px; 
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 5px;
    height: 18px;
}
.ccl-button-collapse i{
    @extend .fa;
    @extend .fa-caret-up;
    font-size: 18px;
    position: absolute;
    bottom: 12px;
    margin: 0 auto;
    left: 0;
    right: 0;                    
}
.ccl-button-expand i{
    @extend .fa; 
    @extend .fa-caret-down;
    font-size: 18px;
    position: absolute;
    bottom: 12px;
    margin: 0 auto;
    left: 0;
    right: 0;                    
}

.ccl-button-close{
    color: $tertiary-fontcolor;
    font-size:12px;
    height:30px;
    width:30px;
     i{
        @extend .fa; 
        @extend .fa-times;

        font-size:20px;
        margin-bottom: 5px;
    }
}

.ccl-button-back-link i{
    @extend .fa;
    @extend .fa-caret-left;
    font-size: 18px; 
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto -13px;
    height: 18px;
}

.ccl-icon-play{
    @extend .fa; 
    @extend .fa-play-circle;
}

.ccl-icon-share{
    @extend .fa; 
    @extend .fa-share-alt;

    &-lg{
        @extend .fa-lg; 
    }
}

.ccl-icon-share-lg{
    @extend .fa-lg; 
    @extend .ccl-icon-share; 
    
}

.ccl-icon-search{
    @extend .fa; 
    @extend .fa-search;
}