﻿.ccl {

    &-tout {
        .price-ast {
            font-size: 60%;
            vertical-align: top;
        }
    }
}
.carousel-widget .price-ast,
.banner-rotator .price-ast,
#HeroSlidesContainer .price-ast {
    font-size: 60%;
    vertical-align: top;
}