﻿$primary-bgcolor: #DC1125;
$primary-bgcolor-hover: #EB003B;
$secondary-bgcolor: #10559A;
$secondary-bgcolor-hover: #1363B7;
$tertiary-bgcolor: transparent;
$tertiary-fontcolor: #10559A !important;
$disabled-bgcolor: #939598;

$button-font: Tempo,"TempoStd","Open Sans",Helvetica,Sans-Serif;
$button-font-color: #FFF !important;
$button-font-size: 14px;
$button-font-kerning: auto;
$button-border-radius: 0px;

$button-padding-large: 30px;
$button-padding-small: 15px 25px;
$button-padding-xsmall: 8px 25px;

.ccl{

    &-button{
        -webkit-appearance: none;
        font-family: $button-font;
        font-kerning: $button-font-kerning;
        font-size: $button-font-size;
        color: $button-font-color;
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        -moz-transition: background-color 0.35s ease-out;
        -o-transition: background-color 0.35s ease-out;
        -webkit-transition: background-color 0.35s ease-out;
        transition: background-color 0.35s ease-out;
        -webkit-border-radius: $button-border-radius;
        -moz-border-radius: $button-border-radius;
        -ms-border-radius: $button-border-radius;
        -o-border-radius: $button-border-radius;
        border-radius: $button-border-radius;    
        padding: $button-padding-large;
        cursor: pointer;

        &-small{
            @extend .ccl-button;
            padding: $button-padding-small;
        }

        &-xsmall{
            @extend .ccl-button;
            padding: $button-padding-xsmall;
        }

        &-primary{
            @extend .ccl-button;
            background-color: $primary-bgcolor;
            
            &:hover{
                background-color: $primary-bgcolor-hover;
            }

            &-small{
                @extend .ccl-button-primary;
                @extend .ccl-button-small;

                 &.view-more {
                    top: 15px;
                    float: right;       
                }
            }
        }

        &-secondary{
            @extend .ccl-button;
            background-color: $secondary-bgcolor;
            
            &:hover{
                background-color: $secondary-bgcolor-hover;
            }

            &-small{
                @extend .ccl-button-secondary;
                @extend .ccl-button-small;

                &.view-more {
                    top: 15px;
                    float: right;       
                }

                &.explore-center {
                    display: block;
                    margin: 0 auto 20px;
                    width: 178px;
                    text-align: center;

                    &.hidden {
                        display: none;
                    }  
                } 

                &.view-all-shore-excursions {
                    margin-bottom: -7px;
                } 
            }
        }

        &-tertiary{
            @extend .ccl-button;
            background-color: $tertiary-bgcolor;
            color: $tertiary-fontcolor;

            &-small{
                @extend .ccl-button-tertiary;
                @extend .ccl-button-small;

            }
        }

        &-disabled{
            @extend .ccl-button;
            background-color: $disabled-bgcolor;
            cursor: default;

            &-small{
                @extend .ccl-button-disabled;
                @extend .ccl-button-small;
            }
        }

        &.loyalty {
            outline: none;
            position: absolute;
            
            &.login{
                margin-left: 100px;
            }
            &:hover{
                text-decoration:none;
            }
        }

        &.benefits{
            float: left;

            &.enroll{
                margin-right: 5px;
                margin-left: 310px;
            }
            &:hover{
                text-decoration:none;
            }
        }

        &-responsive{
            width: 100%;
        }
    }
    
}


